.itemCatalogo {
    margin-top: 20px;
}

.cardNovedad {
    width: 280px;
    text-align: center;
}

.fecha {
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.slider {
    plex-grid {
        display: flex;
    }

    .plex-dots-wrapper {
        left: 0 !important;
    }
}